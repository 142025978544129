<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="maincontent" id="content">
    <div
      class="maincontentinner"
      style="opacity: 1; left: 0px; transition: all 0.5s ease 0s"
    >
      <div class="mt_wrap">
        <h1 class="ttl_1">{{ conts.title }}<span></span></h1>
        <nav class="breadcrumb_wrap">
          <ul class="breadcrumb">
            <li>
              <router-link :to="{ name: 'Dashboard' }">
                {{ conts.breadcrumb.dashboard }}
              </router-link>
            </li>

            <li>
              <span>{{ conts.breadcrumb.calendar }}</span>
            </li>
          </ul>
        </nav>
      </div>
      <div class="action_wrap m_b5" v-if="commonValidationError">
        <p class="s_err">
          入力内容に不備があります。<span
            ><i class="fas fa-times msg_remove"></i
          ></span>
        </p>
      </div>

      <div
        class="modal"
        id="editModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div class="modal_inner confirm">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div id="fm">
                  <div class="modal_header">
                    <p class="ttl_header" id="start_time_in"></p>
                    <input
                      type="hidden"
                      readonly
                      name="start_date"
                      id="start_time"
                    />
                  </div>
                  <div class="modal_wrap">
                    <div class="for_wrap h_80">
                      <div class="modal_contents">
                        <div class="form_outwrap">
                          <div class="wrap wid_100per p_r0 m_b0">
                            <div class="in_wrap"></div>
                          </div>
                        </div>
                        <div
                          class="form_outwrap repeat_rule"
                          style="display: none"
                        >
                          <div class="wrap m_b10">
                            <div class="in_wrap">
                              <label>
                                <input
                                  type="checkbox"
                                  name="leave_rule"
                                  id="leave_rule"
                                  class="chkbox"
                                  v-model="schedule.leave_rule"
                                /><span
                                  :dusk="
                                    conts.editmodal.exclude_from_repetition
                                  "
                                ></span
                                ><span class="chktxt">{{
                                  conts.editmodal.exclude_from_repetition
                                }}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="form_outwrap">
                          <div>
                            <p class="ttl_3 m_b5">
                              {{ conts.editmodal.select_a_business_category }}
                            </p>
                            <div class="delete commandtext right">
                              <button
                                type="submit"
                                class="btn btn-default tooltip tip-bottom cln_form_btn"
                                name="submit_type"
                                value="delete"
                                title="削除"
                                dusk="delete_data"
                                @click="deletePerticularEvent()"
                              >
                                {{ conts.editmodal.delete_appointment }}
                              </button>
                            </div>
                          </div>
                          <div class="wrap">
                            <div class="in_wrap">
                              <label>
                                <input
                                  type="radio"
                                  name="title"
                                  :value="conts.editmodal.regular_holiday"
                                  id=""
                                  class="radiobox title"
                                /><span dusk="定休日_inPopup"></span
                                ><span class="radiotxt">
                                  {{ conts.editmodal.regular_holiday }}</span
                                >
                              </label>
                            </div>
                          </div>
                          <div class="wrap">
                            <div class="in_wrap">
                              <label>
                                <input
                                  type="radio"
                                  name="title"
                                  :value="conts.editmodal.temporary_holiday"
                                  id=""
                                  class="radiobox title"
                                /><span dusk="臨時休業日_inPopup"></span
                                ><span class="radiotxt">
                                  {{ conts.editmodal.temporary_holiday }}</span
                                >
                              </label>
                            </div>
                          </div>
                          <div class="wrap">
                            <div class="in_wrap">
                              <label>
                                <input
                                  type="radio"
                                  name="title"
                                  :value="conts.editmodal.special_business_day"
                                  id=""
                                  class="radiobox title"
                                /><span dusk="特別営業日_inPopup"></span
                                ><span class="radiotxt">
                                  {{
                                    conts.editmodal.special_business_day
                                  }}</span
                                >
                              </label>
                            </div>
                          </div>
                          <div class="wrap">
                            <div class="in_wrap">
                              <label>
                                <input
                                  type="radio"
                                  name="title"
                                  :value="conts.editmodal.free_release_date"
                                  id=""
                                  class="radiobox title"
                                /><span dusk="無料公開日_inPopup"></span
                                ><span class="radiotxt">
                                  {{ conts.editmodal.free_release_date }}</span
                                >
                              </label>
                            </div>
                          </div>
                          <div class="wrap p_r0">
                            <div class="in_wrap">
                              <label>
                                <input
                                  type="radio"
                                  name="title"
                                  :value="conts.editmodal.there_is_a_notice"
                                  id=""
                                  class="radiobox title news_chk"
                                /><span dusk="お知らせあり_inPopup"></span
                                ><span class="radiotxt">{{
                                  conts.editmodal.there_is_a_notice
                                }}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          class="form_outwrap"
                          id="txtbody"
                          style="display: none"
                        >
                          <div class="wrap wid_100per p_r0">
                            <div class="in_wrap">
                              <textarea
                                name="body"
                                id="body"
                                cols="30"
                                rows="10"
                                :placeholder="
                                  conts.place_holder
                                    .enter_the_content_of_the_notification
                                "
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal_footer">
                    <div class="flex_wrap f_c">
                      <div class="btn_norm reverse" id="cancel_bk">
                        <button
                          type="button"
                          :value="conts.button.cancel"
                          dusk="delete_data"
                          @click="closeModal()"
                        >
                          {{ conts.button.cancel }}
                        </button>
                      </div>

                      <div class="btn_norm right m_r0">
                        <button
                          @click="calendarSumbmit()"
                          class="btn btn-primary cln_form_btn"
                          :value="conts.button.save"
                          dusk="save"
                          id="calendarSubmit"
                        >
                          {{ conts.button.save }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p
            id="close_btn-c"
            @click="closeModal()"
            class="pop-remove_btn ttoltip tip-top"
          >
            <i class="fas fa-times"></i>
          </p>
        </div>
        <!--modal_inner-->
      </div>
      <!--modal-->

      <Spinner v-if="spinner" />
      <!--modal-->
      <SuccessMessage v-if="successMessage" />

      <div class="contentout_wrap">
        <div class="content_wrap cln_column">
          <div class="box_wrap right">
            <h2 class="ttl_2 acc_menu menuopen">{{ conts.sub_title }}</h2>
            <div class="acc_wrap" style="display: block">
              <div>
                <p class="ttl_3">{{ conts.grid.rules_being_set }}</p>
                <ul class="cln_rule">
                  <template
                    v-for="schedules in allSchedulsLoad"
                    :key="schedules.id"
                  >
                    <li v-if="schedules.day_of_the_week != null">
                      <form>
                        <p>
                          {{ schedules.type }} :
                          {{ getPeriodsText(schedules.period_type) }}
                          {{ getDateText(schedules.day_of_the_week) }}
                        </p>
                        <button
                          form="form1"
                          type="submit"
                          value="削除"
                          class="cln_remove_btn remove_btn tooltip tip-right deleart"
                          title="削除"
                          @click="deleteSchedules(schedules.id)"
                          dusk="delete_calender_rule"
                        >
                          <i class="material-icons">close</i>
                        </button>
                      </form>
                    </li>
                    <!-- <p v-else>設定中のルールはありません</p> -->
                  </template>
                </ul>
              </div>
              <div>
                <hr class="m_t20 m_b20" />
                <p class="ttl_6">
                  {{ conts.grid.new_calendar_rule_settings
                  }}<span
                    class="tooltip tip-left"
                    :title="conts.grid.please_enter_from_STEP1_to_STEP3"
                    ><i class="far fa-question-circle m_l10 fs_130per"></i
                  ></span>
                </p>
              </div>
              <Form
                enctype="multipart/form-data"
                autocomplete="off"
                @submit="onSubmit"
                :validation-schema="schema"
                class="createForm"
                ref="form"
              >
                <div class="form_outwrap">
                  <div class="wrap wid_100per p_r0">
                    <p class="ttl_3 m_b10">
                      {{ conts.grid.STEP1_Select_a_candidate }}
                    </p>
                    <div class="in_wrap">
                      <div class="select_wrap">
                        <Field
                          name="period_type"
                          as="select"
                          v-model="schedule.period_type"
                          id="calendarPeriods"
                        >
                          <option value="">
                            {{ conts.grid.please_select }}
                          </option>
                          <option value="all_week">
                            {{ conts.select_day_order.Weekly }}
                          </option>
                          <option value="1th_week">
                            {{
                              conts.select_day_order.First_week_of_every_month
                            }}
                          </option>
                          <option value="2th_week">
                            {{
                              conts.select_day_order.second_week_of_every_month
                            }}
                          </option>
                          <option value="3th_week">
                            {{
                              conts.select_day_order.third_week_of_every_month
                            }}
                          </option>
                          <option value="4th_week">
                            {{
                              conts.select_day_order.fourth_week_of_every_month
                            }}
                          </option>
                          <option value="5th_week">
                            {{
                              conts.select_day_order.fifth_week_of_every_month
                            }}
                          </option>
                          <option value="1th_dayof">
                            {{ conts.select_day_order.ten_th_day_of_the_month }}
                          </option>
                          <option value="2th_dayof">
                            {{
                              conts.select_day_order.twenty_th_day_of_the_month
                            }}
                          </option>
                          <option value="3th_dayof">
                            {{
                              conts.select_day_order.thirty_th_day_of_the_month
                            }}
                          </option>
                          <option value="4th_dayof">
                            {{
                              conts.select_day_order.fourty_th_day_of_the_month
                            }}
                          </option>
                          <option value="5th_dayof">
                            {{
                              conts.select_day_order.fifty_th_day_of_the_month
                            }}
                          </option>
                        </Field>
                      </div>
                      <div class="msg_wrap" v-if="periodTypeError">
                        <span class="each_msg">
                          必須入力です

                          <span class="trg"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!--form_outwrap-->
                <p class="ttl_3 m_b10">
                  {{ conts.grid.STEP2_Select_a_candidate }}
                </p>
                <div class="form_outwrap">
                  <div class="wrap">
                    <div class="in_wrap">
                      <label>
                        <Field
                          type="checkbox"
                          name="day_of_the_week"
                          v-model="schedule.day_of_the_week"
                          value="0"
                          class="chkbox"
                        /><span :dusk="conts.day.sunday"></span
                        ><span class="chktxt">{{ conts.day.sunday }}</span>
                      </label>
                    </div>
                  </div>
                  <div class="wrap">
                    <div class="in_wrap">
                      <label>
                        <Field
                          type="checkbox"
                          name="day_of_the_week"
                          value="1"
                          v-model="schedule.day_of_the_week"
                          class="chkbox"
                        /><span :dusk="conts.day.monday"></span
                        ><span class="chktxt">{{ conts.day.monday }}</span>
                      </label>
                    </div>
                  </div>
                  <div class="wrap">
                    <div class="in_wrap">
                      <label>
                        <Field
                          type="checkbox"
                          name="day_of_the_week"
                          value="2"
                          v-model="schedule.day_of_the_week"
                          class="chkbox"
                        /><span :dusk="conts.day.tuesday"></span
                        ><span class="chktxt">{{ conts.day.tuesday }}</span>
                      </label>
                    </div>
                  </div>
                  <div class="wrap">
                    <div class="in_wrap">
                      <label>
                        <Field
                          type="checkbox"
                          name="day_of_the_week"
                          value="3"
                          class="chkbox"
                          v-model="schedule.day_of_the_week"
                        /><span :dusk="conts.day.Wednesday"></span
                        ><span class="chktxt">{{ conts.day.Wednesday }}</span>
                      </label>
                    </div>
                  </div>
                  <div class="wrap">
                    <div class="in_wrap">
                      <label>
                        <Field
                          type="checkbox"
                          name="day_of_the_week"
                          v-model="schedule.day_of_the_week"
                          value="4"
                          class="chkbox"
                        /><span :dusk="conts.day.thursday"></span
                        ><span class="chktxt">{{ conts.day.thursday }}</span>
                      </label>
                    </div>
                  </div>
                  <div class="wrap">
                    <div class="in_wrap">
                      <label>
                        <Field
                          type="checkbox"
                          name="day_of_the_week"
                          v-model="schedule.day_of_the_week"
                          value="5"
                          class="chkbox"
                        /><span :dusk="conts.day.friday"></span
                        ><span class="chktxt">{{ conts.day.friday }}</span>
                      </label>
                    </div>
                  </div>
                  <div class="wrap">
                    <div class="in_wrap">
                      <label>
                        <Field
                          type="checkbox"
                          name="day_of_the_week"
                          v-model="schedule.day_of_the_week"
                          value="6"
                          class="chkbox"
                        /><span :dusk="conts.day.saturday"></span
                        ><span class="chktxt">{{ conts.day.saturday }}</span>
                      </label>
                    </div>
                  </div>
                  <div class="wrap">
                    <div class="msg_wrap" v-if="dayOfWeekError">
                      <span class="each_msg">
                        必須入力です
                        <span class="trg"></span>
                      </span>
                    </div>
                  </div>
                </div>
                <!--form_outwrap-->
                <p class="ttl_3 m_b10">
                  {{ conts.grid.STEP2_Select_a_candidate }}
                </p>
                <div class="form_outwrap">
                  <div class="wrap">
                    <div class="in_wrap">
                      <label>
                        <Field
                          type="radio"
                          name="type"
                          v-model="schedule.type"
                          :value="conts.editmodal.regular_holiday"
                          id=""
                          class="radiobox"
                        /><span :dusk="conts.editmodal.regular_holiday"></span
                        ><span class="radiotxt">{{
                          conts.editmodal.regular_holiday
                        }}</span>
                      </label>
                    </div>
                  </div>
                  <div class="wrap">
                    <div class="in_wrap">
                      <label>
                        <Field
                          type="radio"
                          name="type"
                          v-model="schedule.type"
                          :value="conts.editmodal.temporary_holiday"
                          class="radiobox"
                        /><span :dusk="conts.editmodal.temporary_holiday"></span
                        ><span class="radiotxt">
                          {{ conts.editmodal.temporary_holiday }}</span
                        >
                      </label>
                    </div>
                  </div>
                  <div class="wrap">
                    <div class="in_wrap">
                      <label>
                        <Field
                          type="radio"
                          name="type"
                          v-model="schedule.type"
                          :value="conts.editmodal.special_business_day"
                          class="radiobox"
                        /><span
                          :dusk="conts.editmodal.special_business_day"
                        ></span
                        ><span class="radiotxt">{{
                          conts.editmodal.special_business_day
                        }}</span>
                      </label>
                    </div>
                  </div>
                  <div class="wrap">
                    <div class="in_wrap">
                      <label>
                        <Field
                          type="radio"
                          name="type"
                          v-model="schedule.type"
                          :value="conts.editmodal.free_release_date"
                          class="radiobox"
                        />
                        <span :dusk="conts.editmodal.free_release_date"></span
                        ><span class="radiotxt"
                          >{{ conts.editmodal.free_release_date }}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="wrap" v-if="typeError">
                    <div class="msg_wrap">
                      <span class="each_msg">
                        必須入力です
                        <span class="trg"></span>
                      </span>
                    </div>
                  </div>
                </div>
                <!--form_outwrap-->
                <div class="m_t30 m_b30">
                  <div class="btn_flec">
                    <button type="submit" dusk="com_save_button">
                      {{ conts.button.save_the_set_rules }}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
            <!--acc_wrap-->
          </div>
          <!--box_wrap-->
          <div class="box_wrap left">
            <h2 class="ttl_2">カレンダー編集</h2>
            <div class="cln_header_wrap">
              <div class="title_wrap">
                <p>
                  <span id="yearttl" class="year"></span
                  ><span id="monthttl" class="month"></span>
                </p>
                <button
                  id="clnprev"
                  class="prev tooltip tip-bottom"
                  title="前の月へ"
                >
                  <i class="material-icons">keyboard_arrow_left</i>
                </button>
                <button
                  id="clnnext"
                  class="next tooltip tip-bottom"
                  title="次の月へ"
                >
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>

              <div class="cln_status">
                <ul>
                  <li class="status_teikyu">
                    <span>:{{ conts.editmodal.regular_holiday }}</span>
                  </li>
                  <li class="status_rinji">
                    <span>:{{ conts.editmodal.temporary_holiday }}</span>
                  </li>
                  <li class="status_tokubetsu">
                    <span>:{{ conts.editmodal.special_business_day }}</span>
                  </li>
                  <li class="status_muryou">
                    <span>:{{ conts.editmodal.free_release_date }}</span>
                  </li>
                  <li class="status_news">
                    <span>:{{ conts.editmodal.there_is_a_notice }}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div id="calendar" style="overflow: auto"></div>
          </div>
          <!--box_wrap-->
        </div>
        <!--content_wrap-->
      </div>
      <!--contentoutwrap-->
    </div>
    <!--maincontentinner-->
  </div>
</template>

<script>
/* eslint-disable */
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import "@/assets/front_component/js/jquery.magnific-popup.min.js";
import "@/assets/front_component/js/slick.min.js";
import moment from "@/assets/front_component/js/fullcalendar/lib/moment.min.js";
import "@/assets/front_component/js/fullcalendar/fullcalendar3.1.0.min.js";
import $ from "jquery";
import CONST from "../../../const/control_panel/calendar";
import { mapState, mapGetters, mapActions } from "vuex";
import SuccessMessage from "../../../components/control_panel/SuccessMessage.vue";
import Spinner from "../../../components/control_panel/Spinner.vue";

import Calendar from "../../../apis/Calendar";

export default {
  setup() {
    let schema = Yup.object().shape({});
    return {
      schema,
    };
  },
  data() {
    return {
      conts: CONST.INDEX,
      schedule: {
        status: "公開",
        leave_rule: false,
      },
      allSchedulsLoad: [],
      allCalendarSchedulsLoad: [],
      fullCalendar: "",
      scheduleType: "create",
      scheduleUpdateId: "",
      typeError: false,
      periodTypeError: false,
      commonValidationError: false,
      dayOfWeekError: false,
      leaveRule: false,
      leaveRuleId: "",
      leaveDate: "",
    };
  },
  methods: {
    ...mapActions("calendar", [
      "getAllSchedules",
      "createSchedules",
      "updateSchedules",
    ]),
    ...mapActions("common", ["setSpinnerStatus", "setSucccessMessageStatus"]),
    closeModal() {
      $(".radiobox").prop("checked", false);
      $("#editModal").removeClass("open");
    },

    deleteSchedules(id) {
      this.$store.dispatch("common/setSpinnerStatus", true, { root: true });
      Calendar.deleteSchedules(id)
        .then(async (response) => {
          if (response != "") {
            this.allSchedulsLoad = this.allSchedulsLoad.filter((item) => {
              return item.id != id;
            });

            $(".modal").removeClass("open");

            $("#calendar").fullCalendar("refetchEvents"); //再描画

            this.setSucccessMessageStatus({
              status: true,
              message: "カレンダーが正常に削除されました。",
            });

            window.scrollTo(0, 0);
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.dispatch("common/setSpinnerStatus", false, {
            root: true,
          });
        });
    },

    calendarSumbmit() {
      if (this.schedule.leave_rule === true) {
        Calendar.scheduleEventRemove(this.leaveRuleId, {
          leave_data: this.leaveDate,
        }).then(async (resp) => {
          if (resp != "") {
            $(".modal").removeClass("open");
            $("#calendar").fullCalendar("refetchEvents"); //再描画

            this.setSucccessMessageStatus({
              status: true,
              message: "カレンダーが正常に削除されました。",
            });
            window.scrollTo(0, 0);

            this.schedule.leave_rule = false;
            this.leaveDate = "";
            this.leaveRuleId = "";
            this.leaveRule = false;
          }
        });
      } else {
        if (this.scheduleType == "create") {
          this.$store.dispatch("common/setSpinnerStatus", true, { root: true });
          Calendar.createSchedules(this.schedule)
            .then(async (response) => {
              if (response != "") {
                $(".modal").removeClass("open");
                await $("#calendar").fullCalendar("refetchEvents"); //再描画
                this.setSucccessMessageStatus({
                  status: true,
                  message: "カレンダーが正常に作成されました。",
                });
                window.scrollTo(0, 0);
              }
            })
            .catch((error) => {})
            .finally(() => {
              this.$store.dispatch("common/setSpinnerStatus", false, {
                root: true,
              });
            });
        } else {
          this.$store.dispatch("common/setSpinnerStatus", true, { root: true });
          Calendar.updateSchedules(this.scheduleUpdateId, this.schedule)
            .then(async (response) => {
              if (response != "") {
                $(".modal").removeClass("open");
                await $("#calendar").fullCalendar("refetchEvents"); //再描画
                this.setSucccessMessageStatus({
                  status: true,
                  message: "カレンダーが正常に更新されました。",
                });
                window.scrollTo(0, 0);
              }
            })
            .catch((error) => {})
            .finally(() => {
              this.$store.dispatch("common/setSpinnerStatus", false, {
                root: true,
              });
            });
        }
      }
    },
    deletePerticularEvent() {
      this.$store.dispatch("common/setSpinnerStatus", true, { root: true });
      Calendar.deleteSchedules(this.scheduleUpdateId)
        .then(async (response) => {
          if (response != "") {
            $(".modal").removeClass("open");
            $("#calendar").fullCalendar("refetchEvents"); //再描画

            this.setSucccessMessageStatus({
              status: true,
              message: "カレンダーが正常に削除されました。",
            });
            window.scrollTo(0, 0);
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.dispatch("common/setSpinnerStatus", false, {
            root: true,
          });
        });
    },
    onSubmit(data, { resetForm }) {
      if (
        (this.schedule.period_type == "" ||
          this.schedule.period_type == null) &&
        (this.schedule.day_of_the_week == "" ||
          this.schedule.day_of_the_week == null ||
          this.schedule.day_of_the_week == undefined ||
          this.schedule.day_of_the_week == "undefined") &&
        (this.schedule.type == "" || this.schedule.type == null)
      ) {
        this.periodTypeError = true;
        this.dayOfWeekError = true;
        this.typeError = true;
        this.commonValidationError = true;
        window.scrollTo(0, 0);
        return false;
      } else {
        this.periodTypeError = false;
        this.dayOfWeekError = false;
        this.typeError = false;
      }

      if (
        this.schedule.period_type == "" ||
        this.schedule.period_type == null ||
        this.schedule.period_type == undefined ||
        this.schedule.period_type == "undefined"
      ) {
        this.periodTypeError = true;
        return false;
      } else {
        this.periodTypeError = false;
      }

      if (
        this.schedule.day_of_the_week == "" ||
        this.schedule.day_of_the_week == null ||
        this.schedule.day_of_the_week == undefined ||
        this.schedule.day_of_the_week == "undefined"
      ) {
        this.dayOfWeekError = true;
        return false;
      } else {
        this.dayOfWeekError = false;
      }

      if (
        this.schedule.type == "" ||
        this.schedule.type == null ||
        this.schedule.type == undefined ||
        this.schedule.type == "undefined"
      ) {
        this.typeError = true;
        return false;
      } else {
        this.typeError = false;
      }

      if (!this.periodTypeError && !this.dayOfWeekError && !this.typeError) {
        this.commonValidationError = false;

        this.$store.dispatch("common/setSpinnerStatus", true, { root: true });
        this.schedule.start_date = "1970-01-01";
        this.schedule.end_date = "2500-12-31";

        Calendar.createSchedules(this.schedule)
          .then(async (response) => {
            if (response != "") {
              $(".modal").removeClass("open");

              this.allSchedulsLoad.push(response.data.data);

              $("#calendar").fullCalendar("refetchEvents"); //再描画

              this.schedule.day_of_the_week = [];
              this.schedule.period_type = "";
              this.schedule.type = "";

              this.setSucccessMessageStatus({
                status: true,
                message: "カレンダーが正常に作成されました。",
              });
              window.scrollTo(0, 0);
            }
          })
          .catch((error) => {})
          .finally(() => {
            this.$store.dispatch("common/setSpinnerStatus", false, {
              root: true,
            });
          });
      }
    },
    getPeriodsText(period) {
      let setPeriod = "";
      switch (period) {
        case "all_week":
          setPeriod = "毎週";
          break;
        case "1th_week":
          setPeriod = "1週目（毎月）";
          break;
        case "2th_week":
          setPeriod = "2週目（毎月）";
          break;
        case "3th_week":
          setPeriod = "3週目（毎月）";
          break;
        case "4th_week":
          setPeriod = "4週目（毎月）";
          break;
        case "5th_week":
          setPeriod = "5週目（毎月）";
          break;
        case "1th_dayof":
          setPeriod = "1回目（毎月)";
          break;
        case "2th_dayof":
          setPeriod = "2回目（毎月)";
          break;
        case "3th_dayof":
          setPeriod = "3回目（毎月)";
          break;
        case "4th_dayof":
          setPeriod = "4回目（毎月)";
          break;
        case "5th_dayof":
          setPeriod = "5回目（毎月)";
          break;
        default:
          setPeriod = "";

          break;
      }
      return setPeriod == "" ? "" : setPeriod + "の";
    },
    getDateText(day) {
      let dayJp = {
        0: "日",
        1: "月",
        2: "火",
        3: "水",
        4: "木",
        5: "金",
        6: "土",
      };
      // // let arr = day.split(",");
      if (day != null) {
        let daySplit = day.split(",");
        let setDay = "";
        for (let index = 0; index < daySplit.length; index++) {
          const element = daySplit[index];
          if (index == daySplit.length - 1) {
            setDay += dayJp[element];
          } else {
            setDay += dayJp[element] + ",";
          }
        }
        setDay += "曜日";
        return setDay;
      }
    },
  },
  components: {
    Form,
    Field,
    Spinner,
    SuccessMessage,
  },
  watch: {
    allSchedules(val) {
      this.allSchedulsLoad = val;
    },
  },
  computed: {
    ...mapState("calendar", ["allSchedules", "calenderSchedules"]),
    ...mapState("common", ["successMessage", "spinner"]),
  },
  created: function(event) {
    this.getAllSchedules();
  },
  beforeMount() {},
  updated: function(event) {},
  mounted: function(event) {
    var vueThis = this;

    $(function() {
      /*  fullCalendarのオプション、カスタム
            ===================================================================*/
      var $fullcalendar = $("#calendar");

      $("#calendar").fullCalendar({
        buttonText: {
          today: "今日",
          month: "月",
          basicWeek: "週",
          listMonth: "月リスト",
        },
        header: {
          // title, prev, next, prevYear, nextYear, today
          left: "",
          center: "title",
          //left: 'title',
          right: "",
        },
        locale: "ja",
        firstDay: 0,
        timeFormat: "HH:mm", // 時間表示フォーマット
        timezone: "Asia/Tokyo", // タイムゾーン設定
        eventLimit: true, // イベント増えた時にリンクボタン表示
        editable: true, // ドラッグアンドドロップ禁止
        slotEventOverlap: true, // イベントの見た目を重ねて表示
        selectable: true, // カレンダー空白部分のドラッグ可能
        selectMinDistance: 1, //
        dayNames: [
          "日曜日",
          "月曜日",
          "火曜日",
          "水曜日",
          "木曜日",
          "金曜日",
          "土曜日",
        ],
        // 曜日略称
        dayNamesShort: ["日", "月", "火", "水", "木", "金", "土"],
        monthNames: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
        ],
        titleFormat: "YYYY/M",
        //viewRender: (view) => {
        //  date = view.start.format('MMMM')
        //  $('#title').text(date);
        //},
        dayClick: function(date, jsEvent, view) {
          // console.log(jsEvent);
          // console.log(view);
          vueThis.schedule.start_date = date;
          vueThis.schedule.end_date = date;
          //カレンダー空白部分クリック時のイベント
          dayClickRuleProc(date, jsEvent, view, "#calendar"); //クリック対象セルがルールを持っている場合の処理
          modalMod(date); //モーダル内inputへのデータ渡し・書き出し
          modalToggle("#editModal"); //モーダル表示非表示切替
        },
        // events: vueThis.allCalendarSchedulsLoad,
        events: function(start, end, timezone, callback) {
          vueThis.$store.dispatch("common/setSpinnerStatus", true, {
            root: true,
          });
          Calendar.schedulesRule()
            .then(async (doc) => {
              let events = [];
              if (!!doc.data.data) {
                await $.map(doc.data.data, function(r) {
                  if (
                    r.type == "お知らせあり" ||
                    r.type == "特別営業日" ||
                    r.type == "定休日" ||
                    r.type == "臨時休業日" ||
                    r.type == "無料公開日"
                  ) {
                    // console.log(r);

                    let pushArray = {
                      id: r.id,
                      title: r.type,
                      body: r.content,
                    };

                    if (r.day_of_the_week != null) {
                      let repeatClass = "rid_" + r.id;

                      switch (r.type) {
                        case "定休日":
                          pushArray.backgroundColor = "#cfcfcf";
                          pushArray.rendering = "background";
                          // pushArray.className = "repeat " + repeatClass;

                          break;
                        case "臨時休業日":
                          pushArray.backgroundColor = "#cbd2f2";
                          pushArray.rendering = "background";
                          // pushArray.className = "repeat " + repeatClass;

                          break;
                        case "特別営業日":
                          pushArray.backgroundColor = "#f3f783";
                          pushArray.rendering = "background";
                          // pushArray.className = "repeat " + repeatClass;

                          break;
                        case "無料公開日":
                          pushArray.backgroundColor = "#fbc7c7";
                          pushArray.rendering = "background";
                          // pushArray.className = "repeat " + repeatClass;

                          break;
                        case "お知らせあり":
                          pushArray.textColor = "red";
                          pushArray.rendering = "background";
                          // pushArray.className = "repeat " + repeatClass;

                          break;

                        default:
                          break;
                      }

                      pushArray.dow = r.day_of_the_week;
                      pushArray.period_type = r.period_type;
                      // pushArray.ranges = [{ start: "1970-01-01", end: "2500-12-31" }];
                      pushArray.ranges = r.ranges;
                      pushArray.rendering = "background";
                      pushArray.textColor = "#666";
                      pushArray.className = "repeat " + repeatClass;
                    } else {
                      if (r.start_date != "") {
                        pushArray.start = r.start_date;
                      }
                      if (r.end_date != "") {
                        pushArray.end = r.end_date;
                      }

                      pushArray.allDay = "";

                      switch (r.type) {
                        case "定休日":
                          pushArray.textColor = "#666";
                          break;
                        case "臨時休業日":
                          pushArray.textColor = "#666";
                          break;
                        case "特別営業日":
                          pushArray.textColor = "#666";
                          break;
                        case "無料公開日":
                          pushArray.textColor = "#666";
                          break;
                        case "お知らせあり":
                          pushArray.textColor = "red";
                          break;
                        default:
                          break;
                      }
                    }
                    events.push(pushArray);
                  }
                });


                callback(events);
              }
            })
            .catch((error) => {})
            .finally(() => {
              vueThis.$store.dispatch("common/setSpinnerStatus", false, {
                root: true,
              });
            });
        },

        
        eventRender: function(events, element) {
          vueThis.schedule.start_date = moment(events.start).format(
            "YYYY-MM-DD"
          );

          vueThis.schedule.end_date = moment(events.start).format("YYYY-MM-DD");

          var jdg;
          if (events.dow) {
            //繰り返し系スケジュールの場合
            jdg = ruleJudge(events, element);
          }
          if (jdg == false) {
            $(this).removeClass(); //.repeatや.r_idなどのクラスを省く
            return false;
          }
        },

        eventClick: function(calEvent, jsEvent, view) {
          
          modalModevent(calEvent, calEvent.title); //モーダル内inputへのデータ渡し・書き出し
          $("#leave_rule")
            .parents(".form_outwrap")
            .css("display", "none");
          modalToggle("#editModal"); //モーダル表示非表示切替
        },
      });

      $(document).ready(function() {
        var year = $fullcalendar.fullCalendar("getDate");
        var month = $fullcalendar.fullCalendar("getDate");
        var titleyear = moment(year).format("YYYY");
        var titlemonth = moment(month).format("MMMM");
        //$("#titlecln").text(title + title2 );
        $("#yearttl").text(titleyear);
        $("#monthttl").text(titlemonth);
      });

      ///Next/Prev buttons
      $("#clnnext").on("click", function() {
        $fullcalendar.fullCalendar("next");
        var year = $fullcalendar.fullCalendar("getDate");
        var month = $fullcalendar.fullCalendar("getDate");
        var titleyear = moment(year).format("YYYY");
        var titlemonth = moment(month).format("MMMM");
        //$("#titlecln").text(title + title2 );
        $("#yearttl").text(titleyear);
        $("#monthttl").text(titlemonth);
      });
      $("#clnprev").on("click", function() {
        $fullcalendar.fullCalendar("prev");
        var year = $fullcalendar.fullCalendar("getDate");
        var month = $fullcalendar.fullCalendar("getDate");
        var titleyear = moment(year).format("YYYY");
        var titlemonth = moment(month).format("MMMM");
        //$("#titlecln").text(title + title2 );
        $("#yearttl").text(titleyear);
        $("#monthttl").text(titlemonth);
      });
      /*  Ajax送信処理（fullcalendarの再描画）
            ===================================================================*/
      $("button.cln_form_btn").on("click", function(event) {});
    });

    $(function() {
      $(".close_btn").on("click", function() {
        $("#editModal").removeClass("open");
        $("#leave_rule")
          .parents(".form_outwrap")
          .css("display", "none");
        $(".title").prop("checked", false);
        return false;
      });
    });
    $(function() {
      $(document).on("click", ".modal", function(e) {
        if (e.target === this) {
          $(this).toggleClass("open");
          $("#leave_rule")
            .parents(".form_outwrap")
            .css("display", "none");
          $(".title").prop("checked", false);
        }
      });
    });
    $(function() {
      $(".news_chk").change(function() {
        var $txtbody = $("#txtbody");
        $txtbody.slideToggle(this.checked);
      });
    });
    $(function() {
      $('input[type="radio"]').on("change", function() {
        vueThis.schedule.type = $(this).val();
        var $txtbody = $("#txtbody");
        if ($(".news_chk").prop("checked")) {
          $txtbody.show();
        } else {
          $txtbody.hide();
        }
      });

      $('textarea[name="body"]').on("keyup", function() {
        console.log($(this).val());
        vueThis.schedule.content = $(this).val();
      });
    });
    $(function() {
      $(document).on("click", ".acc_menu", function() {
        var $this = $(this);
        var html = "";
        // メニュー表示/非表示
        $this.stop();
        $this.next(".acc_wrap").stop();
        $this.next(".acc_wrap").slideToggle("fast");
        $this.toggleClass("menuopen");
        $(".btn-area").append(html);
      });
    });
    //▼timepicker
    $(function() {
      //▼datepicker
      $(".input-date").datepicker({
        format: "yyyy-m-d",
        autoclose: true,
      });

      $(".set-now-date").on("click", function() {
        $(this)
          .siblings(".input-date")
          .datepicker("setDate", new Date());
      });
    });

    /*-----------------------------------------------------
	クリック対象セルがルールを持っている場合の処理
--------------------------------------------------------*/
    function dayClickRuleProc(date, jsEvent, view, target) {
      $("#leave_rule")
        .parents(".form_outwrap")
        .css("display", "none");

      //
      //▼クリックされた日のイベントを扱う

      $(target).fullCalendar("clientEvents", function(e) {
        if (
          moment(date).format("YYYY-MM-DD") ===
          moment(e.start).format("YYYY-MM-DD")
        ) {
          var target_repeat = e.className;
          var rule_id;

          if (target_repeat.indexOf("repeat") >= 0) {
            vueThis.leaveRule = true;

            //class内にrepeat：繰り返しルールが存在するか
            rule_id = target_repeat[1].replace("rid_", "");

            vueThis.leaveRuleId = target_repeat[1].replace("rid_", "");

            vueThis.leaveDate = moment(date).format("YYYY-MM-DD");

            $("#leave_rule")
              .parents(".form_outwrap")
              .css("display", "block");
            $("#leave_rule").val(rule_id);
          } else {
            $("#leave_rule")
              .parents(".form_outwrap")
              .css("display", "none");
          }
        }
      });
    }

    /*-----------------------------------------------------
	モーダル内inputへのデータ渡し・書き出し
--------------------------------------------------------*/
    function modalMod(event, eTitle) {
      //クリック対象セルがルールを持っている場合の処理

      vueThis.schedule.type = "";

      $("#start_time").val(moment(event._d).format("YYYY-MM-DD"));
      $("#start_time_in").text(moment(event._d).format("YYYY-MM-DD"));
      $("#finish_time").val(moment(event.end).format("YYYY-MM-DD"));
      $("#title").val(event.title);
      $("#body").val(event.body);
      $("#event_id").val(event.id);
      vueThis.scheduleType = "create";
      $(".radiobox").prop("checked", false);
    }
    //▼イベントクリックは便宜的に下記を使用
    function modalModevent(event, eTitle) {
      //クリック対象セルがルールを持っている場合の処理
      $(".title").each(function() {
        if ($(this).val() == eTitle) {
          $(this).prop("checked", "true");
        }
      });

      $("#start_time").val(moment(event._start).format("YYYY-MM-DD"));
      $("#start_time_in").text(moment(event._start).format("YYYY-MM-DD"));
      $("#finish_time").val(moment(event.end).format("YYYY-MM-DD"));
      $("#title").val(event.title);
      $("#body").val(event.body);
      $("#event_id").val(event.id);
      vueThis.scheduleType = "update";
      vueThis.scheduleUpdateId = event.id;
      vueThis.schedule.type = event.title;
      vueThis.schedule.start_date = moment(event._start).format("YYYY-MM-DD");
      vueThis.schedule.end_date = moment(event._start).format("YYYY-MM-DD");
    }

    /*-----------------------------------------------------
	繰り返し（schedule_rule）の場合の書き出し判定
--------------------------------------------------------*/
    function ruleJudge(events, element) {
      //書き出す日付
      var thisDay = moment(events.start._d);
      var eventPeriodType = events.period_type;
      var week;
      var n;
      // console.log(events.period_type.match(/th_week/));
      /*繰り返し週(週数指定)
    -------------------------*/
      if (events.period_type.match(/th_week/)) {
        week = events.period_type.replace("th_week", ""); //データの繰り返し設定週を取得

        n = Math.floor((thisDay.date() - thisDay.day() + 12) / 7); //現在日付が第何週かを計算

        if (week != n) {
          //対応する週数でない場合は書き出し中止返り値としてfalse
          return false;
        }
      }

      /*繰り返し週(第〇曜日指定)
    -------------------------*/
      if (events.period_type.match(/th_dayof/)) {
        week = events.period_type.replace("th_dayof", ""); //データの繰り返し設定週を取得
        n = Math.floor((events.start._d.getDate() - 1) / 7) + 1; //現在日付が第何曜日かを計算

        if (week != n) {
          //対応する週数でない場合は書き出し中止返り値としてfalse
          return false;
        }
      }

      /*期間（除外日から）
    -------------------------*/
      var $flag = false;
      for (var i = 0, len = events.ranges.length; i < len; ++i) {
        if (thisDay.isBetween(events.ranges[i].start, events.ranges[i].end)) {
          $flag = true;
        }
      }
      if (!$flag) {
        //書き出し中止返り値としてfalse
        return false;
      }
    }

    $(document).on("click", ".pop-modal", function(e) {
      // prevent default action
      e.preventDefault();
      $(this)
        .magnificPopup({
          type: "inline",
          preloader: false,
          closeOnContentClick: true,
          closeBtnInside: true,
          removalDelay: 300,
          gallery: {
            //enabled: true,
          },
          callbacks: {
            beforeOpen: function() {
              this.st.image.markup = this.st.image.markup.replace(
                "mfp-figure",
                "mfp-figure mfp-with-anim"
              );
              this.st.mainClass = this.st.el.attr("data-effect");
            },
          },
        })
        .magnificPopup("open");
    });

    //▼表示-＞非表示処理
    function modalToggle(target) {
      $("#txtbody").hide();
      $(target).addClass("open");
      if ($(".news_chk").prop("checked")) {
        $("#txtbody").show();
      }
    }

    /*-----------------------------------------------------
    フロントトップ：カレンダーイベントクリック
--------------------------------------------------------*/
    function eventClick(calEvent) {
      var beforeDate = moment(calEvent.start._d, "YYYY-MM-DD");
      $("#information_date").html(beforeDate.format("YYYY年MM月DD日"));
      $("#information_date").attr("datetime", beforeDate.format("YYYY-MM-DD"));
      $("#information_body").html(calEvent.body);
    }
  },

  errorCaptured: function(err, instance, info) {
    return false;
  },
};
</script>

<style>
.fc-day-number {
  pointer-events: none;
}
.repeat_rule {
  display: none;
}

.repeat_event {
  background: #ddd;
}
.fc-time {
  display: none;
}
.fc-content {
  text-align: center;
  cursor: pointer;
}
.fc-title {
  font-size: 1.3rem;
}
.fc-content {
  background-color: #eeeeee;
}
#calendar td {
  cursor: pointer;
}
.fc-day-header {
  cursor: default;
}
.fc-day-grid-container.fc-scroller {
  height: auto !important;
  overflow-y: auto;
  overflow: auto !important;
}

.event-full {
  /* color: #fff; */
  /* vertical-align: middle !important; */
  text-align: center;
  /* opacity: 1; */
}
</style>
<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
</style>
